.banner {
    text-align: center;

    img {
        max-width: 100%;
        border-radius: 3px;

        &.banner-desktop {
            display: block;
            margin: 0 auto;
        }

        &.banner-mobile {
            display: none;
        }
    }
}

@media (max-width: 480px) {
    .banner {
        img {
            &.banner-desktop {
                display: none;
            }

            &.banner-mobile {
                width: 100%;
                display: block;
            }
        }
    }
}